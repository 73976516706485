// store/modules/auth.js

const state = {
  user: {
    isLoggedIn: false,
    token: null, // Set this to an appropriate initial value
    userDetails: {}
  },
  userTwoFactorSetUp: {
    qrCodeImage: null,
    secrest: null,
    twoFactorToken: null,
  }
  // user: {
  //   isLoggedIn: true,
  //   token: '1346', // Set this to an appropriate initial value
  //   userDetails: {
  //     username: 'Damg',
  //     email: 'email@email.com'
  //   },
  // },
};

const mutations = {
  SET_USER(state, user) {
    state.user = { ...state.user, ...user };
  },
  SET_TWO_FACTOR_CONFING(state, twoFactorConfig) {
    state.userTwoFactorSetUp = {
      ...state.twoFactorConfig,
      ... twoFactorConfig,
    }
  }
};

const actions = {
  setUser({ commit }, user) {
    commit('SET_USER', user);
  },
  setTwoFactorConfig({commit}, twoFactorConfig) {
    commit('SET_TWO_FACTOR_CONFING',twoFactorConfig);
  }
};

const getters = {
  user: (state) => state.user,
  userTwoFactorSetUp: (state) => state.userTwoFactorSetUp
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
