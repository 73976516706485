import directDebitTransactionRoutes from '@/views/reports/direct-debit-transactions/routes';

export default [
  {
    path: '/reports',
    name: 'Reports',
    meta: {
      requiresAuth: true,
      permission: 'reports/direct-debit-transaction',
      title: 'Reportes',
      module: 'report',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-file-chart'
    },
    component: () =>
      import(
        /* webpackChunkName: "polls" */ '@/views/reports/reportLayout.vue'
      ),
    children: [...directDebitTransactionRoutes]
  }
];
